@import url("https://fonts.googleapis.com/css2?family=Montagu+Slab:wght@600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.sans-serif {
  font-family: 'Roboto', sans-serif;
}

.serif {
  font-family: "Montagu Slab", serif;
}

.pattern {
  @apply bg-gray-900;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%231f2937' fill-opacity='0.37'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}
